import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import { Container, Row, Column, Stack } from '../../components/Layout'
import Header from '../../components/Header'
import SectionCard from '../../components/SectionCard'
import CaseStudyDetails from '../../components/CaseStudyDetails'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Footer from '../../components/Footer'

export const frontmatter = {
  title: 'Wishli',
  description:
    'A memorable, sharable, delightful wishlist experience. Take out the guesswork and let your loved ones know what you truly want.',
  thumbnail: '../../images/work/wishli/thumbnail.png',
  order: 1,
}

const Wishli = ({ data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.siteInfo.siteMetadata.title}</title>
      <link rel="canonical" href={data.siteInfo.siteMetadata.url} />
      <meta
        name="description"
        content={data.siteInfo.siteMetadata.description}
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
    </Helmet>
    <Header title={frontmatter.title} intro={frontmatter.description} secondary>
      <Img
        fluid={data.asset01.childImageSharp.fluid}
        alt="mobile and desktop wishlist"
      />
    </Header>
    <Container>
      <Row verticalSpacing={[6, 0]}>
        <CaseStudyDetails
          items={[
            { key: 'Project Length (Conceptual)', value: '20 hours' },
            { key: 'Primary Design Tool', value: 'Figma' },
            { key: 'My Role', value: 'Designer/Creator' },
          ]}
        />
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Text>Problem</Text>
          <Text>
            Every birthday or holiday season, family members love to ask for a
            wishlist. Some gift ideas live in an Amazon wish list, while others
            might be stored on a Google doc with links. It is inconvenient to
            send multiple wish lists to family and friends who ask for them.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Text>Solution</Text>
          <Text>
            One home for gift ideas, an easy way to share them, and a delightful
            experience of purchasing gifts for others.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={6}>
          <Heading level={1.5} as="h2">
            Initial Conception
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Text>
            To get started, I created some notes to quickly capture the main
            idea of the app. I listed out the problem, why it was a problem, and
            the solution.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Text>
            In my original thought process I wanted to eliminate the need for a
            user to create an account since that can be a deterrent for some
            users to commit to trying a new product. However, as the project
            rolled out it became necessary for users who create wish lists
            (list-owners) to create an account in order to differentiate between
            list-owners and list-visitors. However, list-visitors have the
            ability to interact with a wish list without needing an account.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Text>
            I also loosely outlined a possible flow for how the app would
            function. Below is a more robust look at how a user can interact
            with the app.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img
            fluid={data.asset02.childImageSharp.fluid}
            alt="notebook sketches"
          />
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={6}>
          <Heading level={1.5} as="h2">
            Project Functionality & Flow
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Text>
            To get a better understanding of how the app would function and how
            users would interact with it, I put together a flowchart to define
            those requirements up front.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[2, 0]}>
        <Column span={6}>
          <Text>
            It was important to think about how users with an account would use
            the app, (creating and sharing wishlists), and how users without an
            account (list-visitors) would interact differently. I created a
            separate flow for each use case.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img
            fluid={data.asset03.childImageSharp.fluid}
            alt="project flowchart"
          />
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={1}>
            <Text>
              Users were given the option to invite another user to collaborate
              with them on a wish list. This use case could be used for wedding
              registries, baby showers, or larger gatherings where gift giving
              is involved.
            </Text>
            <Text>
              The flow for list-visitors is different from list-owners because
              they do not have permission to edit items on a wishlist. They are
              able to view wish list items, purchase through a 3rd party
              website, and mark an item as purchased to avoid duplicate gifts.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={12}>
          <Heading level={1.5} as="h2">
            Low-Fidelity Designs
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              After establishing the flow, my next step was to put together some
              low-fidelity screens. This step was a valuable in the design
              process because it allowed for quick iterations and kept my focus
              on layout and user experience rather than stepping into type and
              color before I had a solid blue-print.
            </Text>
            <Text>
              Across various iterations I was able to see how placement and
              expression of certain elements would lend itself to better
              usability. For example, deciding between a large button across the
              bottom-center of a screen, or a small button at the top right of
              the screen. Best practices show that users prefer to tap buttons
              with their thumb, and their reach is best near the bottom of the
              screen.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img
            fluid={data.asset04.childImageSharp.fluid}
            alt="low fidelity designs"
          />
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={12}>
          <Heading level={1.5} as="h2">
            User States
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={1}>
            <Text>
              Progressing into high-fidelity designs was made much easier by the
              legwork done with the low-fidelity screens. This project highly
              utilized Figma’s variants feature when it came to fleshing out
              different user states on a component level. Below are just a few
              examples.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img fluid={data.asset05.childImageSharp.fluid} alt="sign up flow" />
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              Focus State: When a form field is in a focus state, the background
              is filled in with a light blue and a blue stroke is applied. The
              user can also tell that they are inputting date into a form field
              because a keyboard pops up and a cursor appears.
            </Text>
            <Text>
              Error State: When an error state in a form field is necessary, the
              background becomes a light red with a red stroke around the
              border. An icon appears to the right of the field with an
              exclamation point and an error message under the field reads
              “Something’s not right”. The “continue button” remains disabled
              until the error is fixed.
            </Text>
            <Text>
              Success State: Used when the users passwords match, a balck
              checkbox icon appears on the right edge of the form field and the
              main CTA “Sign Up” button transforms from an inactive state to a
              default state.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={12}>
          <Heading level={1.5} as="h2">
            Accessibility
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              Creating experiences with accessibility at the forefront of design
              decisions is one of the best ways a designer can empathize with
              their users. From error states, type size, to primary and
              secondary colors, accessibility played a key role in my decision
              making process.
            </Text>
            <Text>
              When it came to designing an error state it was clear that there
              were additional measures to be taken rather than solely relying on
              the color red. Below are a few variations of what users with
              different types of color vision would see when experiencing an
              error state.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img
            fluid={data.asset06.childImageSharp.fluid}
            alt="color vision variations"
          />
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              For users with typical color vision, the red background and red
              stroke would be a sufficient indication that an error has
              occurred. For individuals with varying types of color vision or
              color blindness, that message could get lost without additional
              indicators.
            </Text>
            <Text>
              Using a Figma plugin called “Color Blind,” I was able to actually
              see what users who had various types of color vision would see and
              it helped my decision making with how to make the error state more
              obvious without solely rely on color to communicate that message.
            </Text>
            <Text>
              I added two clarifying measures: an error icon on the right side
              of the form field with an exclamation mark - a clear indicator
              that something is happening – and a short error message beneath
              the form field. Users who had no color vision would understand
              that an error needed to be fixed before progressing further
              through the signup flow.
            </Text>
          </Stack>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img fluid={data.asset07.childImageSharp.fluid} alt="color palette" />
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Text>
            My goal was to choose color values that had contrast ratio scores of
            4.5 and above. I was happy with the primary color reaching 6.43
            because in order to reach a AAA score of 7.0 would have drastically
            changed the look and feel of the blue. I made sure all of the
            supporting hues were of high contrast, well surpassing the AAA
            score.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={12}>
          <Heading level={1.5} as="h2">
            Modality
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              When a user wants to create a new wish list, a modal appears for
              them to complete the task. I felt that a modal approach would be a
              better approach for completing a task rather than redirecting the
              user to a new page.
            </Text>
            <Text>
              I chose to go the modality route because it gives the user context
              to where they just were, since they can still see parts of the
              previous screen behind the modal, but also interrupts an expected
              flow to capture their attention and encourage them to complete the
              task. Keeping the context of the action in view would help prevent
              confusion and frustration as to what the user is trying to
              accomplish.
            </Text>
          </Stack>
        </Column>
      </Row>
      <div className="bg-light-blue mt-8 p-16">
        <Row verticalSpacing={[4, 2.5]}>
          <Column span={12}>
            <Img
              fluid={data.asset08.childImageSharp.fluid}
              alt="create new wishlist modal"
            />
          </Column>
        </Row>
      </div>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              In addition to preserving context, the modal utilizes labels above
              the form fields so they will always be in view. This design
              decision reduces the chance for the user to forget what type of
              information they were about to input.
            </Text>
          </Stack>
        </Column>
      </Row>
      <div className="bg-light-blue mt-8 p-16">
        <Row verticalSpacing={[4, 2.5]}>
          <Column span={12}>
            <Img
              fluid={data.asset09.childImageSharp.fluid}
              alt="product view modal"
            />
          </Column>
        </Row>
      </div>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={6}>
          <Heading level={1.5} as="h2">
            Visual
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Stack space={2}>
            <Text>
              Inspired by Pinterest, I wanted to make the wishlist experience
              extremely visually appealing by utilizing lots of imagery and
              rounded corners to give a friendly feeling.
            </Text>
            <Text>
              By pasting the url to the wishlist item and scraping the image
              from a 3rd party site, the user is saved the hassle of uploading
              an image. Having images for each item on the list is much more
              engaging than simply using text.
            </Text>
            <Text>
              I also recognize the importance for gift-buyers to see the the
              price of each item right up front and made it highly visible to
              allow users to quickly scan a wish list and choose a gift within
              their price range. This prevents frustration from users having to
              individual click on each item and dig for a price.
            </Text>
          </Stack>
        </Column>
        <Column offset={8} span={5}>
          <Img
            fluid={data.asset10.childImageSharp.fluid}
            alt="mobile wish list"
          />
        </Column>
      </Row>
      <div className="bg-light-blue mt-8 p-16">
        <Row verticalSpacing={[4, 4]}>
          <Column span={12}>
            <Img
              fluid={data.asset11.childImageSharp.fluid}
              alt="create new wishlist modal"
            />
          </Column>
        </Row>
      </div>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={6}>
          <Heading level={1.5} as="h2">
            Prototyping
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Text>
            Working through the flow with high fidelity screens with Figma’s
            prototype feature was an excellent way to discover where users might
            reach a dead end, and how I could redirect them back to adding more
            content.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={12}>
          <Img fluid={data.asset12.childImageSharp.fluid} alt="prototyping" />
        </Column>
      </Row>
      <Row verticalSpacing={[4, 0]}>
        <Column span={6}>
          <Text>
            This exercise also allowed me to see the project start to come to
            life. It also was a blind-spot check and showed me areas that I had
            previously overlooked.
          </Text>
        </Column>
      </Row>
      <Row verticalSpacing={[12.5, 0]}>
        <Column span={6}>
          <Heading level={1.5} as="h2">
            Strengths & Challenges
          </Heading>
        </Column>
      </Row>
      <Row verticalSpacing={[4, 6]}>
        <Column span={5}>
          <Heading level={2} as="h2">
            Strengths
          </Heading>
          <ul className="list-disc list-outside">
            <li>Accessible color palette</li>
            <li>
              Logical flow pattern to provide users with a seamless experience
            </li>
            <li>Design feels clean and easy to use</li>
          </ul>
        </Column>
        <Column span={5}>
          <Heading level={2} as="h2">
            Challenges
          </Heading>
          <ul className="list-disc list-outside">
            <li>
              Scraping images and data from url’s is not as reliable as
              anticipated
            </li>
            <li>
              Users would have to know the email address of whoever they want to
              share their list with
            </li>
            <li>
              List-visitors have to remember to return to the wish list and mark
              an item as “purchased”
            </li>
          </ul>
        </Column>
      </Row>
    </Container>
  </>
)

export const query = graphql`
  query WishliQuery {
    siteInfo: site {
      siteMetadata {
        name
        title
        description
        url
      }
    }

    asset01: file(relativePath: { eq: "work/wishli/asset-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset02: file(relativePath: { eq: "work/wishli/asset-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset03: file(relativePath: { eq: "work/wishli/asset-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset04: file(relativePath: { eq: "work/wishli/asset-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset05: file(relativePath: { eq: "work/wishli/asset-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset06: file(relativePath: { eq: "work/wishli/asset-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset07: file(relativePath: { eq: "work/wishli/asset-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset08: file(relativePath: { eq: "work/wishli/asset-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset09: file(relativePath: { eq: "work/wishli/asset-09.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset10: file(relativePath: { eq: "work/wishli/asset-10.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset11: file(relativePath: { eq: "work/wishli/asset-11.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }

    asset12: file(relativePath: { eq: "work/wishli/asset-12.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`

export default Wishli
