import React from 'react'
import styled from 'styled-components'

import { Stack } from './Layout'
import Heading from './Heading'
import Link from './Link'

const FooterWrapper = styled.footer`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 512px;
  margin-top: 8rem;
`

const Emoji = styled.div`
  font-size: 48px;
  line-height: 48px;
`

const Footer = ({ backgroundColor = '#C3E5C3' }) => (
  <FooterWrapper id="contact">
    <Stack space={1}>
      <Emoji>
        <span role="img" aria-label="email">
          ✉️
        </span>
      </Emoji>
      <Stack space={0.25}>
        <Heading level={2} as="h4">
          Don't be shy, say hi!
        </Heading>
        <Link href="mailto:hannah.baradic@gmail.com">
          hannah.baradic@gmail.com
        </Link>
      </Stack>
    </Stack>
    <svg
      width="100%"
      height="512"
      viewBox="0 0 1440 512"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        height: '512px',
        width: '100%',
        zIndex: '-1',
      }}
    >
      <path
        d="M-128.421 102.494L-118.921 361.494L-118.921 540.994L1538.08 540.994V34.4941C1422.9 70.5593 1360.78 135.431 1241.08 119.994C1157.72 109.245 1052.58 71.4941 1052.58 71.4941C1052.58 71.4941 781.579 6.99414 622.579 48.4941C491.005 82.8359 419.353 116.357 284.079 102.494C217.982 95.7204 184.837 70.515 119.079 60.9941C27.0935 47.6758 -128.421 102.494 -128.421 102.494Z"
        fill={backgroundColor}
      />
      <path
        d="M-142 63.0215L-118.5 342.521L-118.5 512.021L1499 487V13.0215C1389.5 67.5215 1417.95 77.1062 1298 90.5215C1222 99.0215 1119.5 76.5215 1053 52.5215C894.821 -4.5658 782 -11.9785 623 29.5215C491.426 63.8632 419.774 97.3846 284.5 83.5215C218.403 76.7477 139.758 39.0424 74 29.5215C-17.9856 16.2031 -142 63.0215 -142 63.0215Z"
        stroke={backgroundColor}
        strokeWidth="5"
      />
    </svg>
  </FooterWrapper>
)

export default Footer
