import React from 'react'
import styled from 'styled-components'

import Heading from './Heading'

const SectionCardWrapper = styled.span`
  padding: 0 0 1.5rem;
`

const SectionCard = ({ background, children }) => (
  <SectionCardWrapper background={background}>
    <Heading level={1.5} as="h3">
      {children}
    </Heading>
  </SectionCardWrapper>
)

export default SectionCard
