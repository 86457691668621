import React from 'react'

import { Container, Row, Column, Stack } from '../components/Layout'
import Heading from '../components/Heading'
import Text from '../components/Text'

const CaseStudyDetails = ({ items }) => (
  <>
    {items.map(item => (
      <Column span={3}>
        <Heading level={4} as="p" secondary style={{ marginBottom: '4px' }}>
          {item.key}
        </Heading>
        <Heading level={3} as="p">
          {item.value}
        </Heading>
      </Column>
    ))}
  </>
)

export default CaseStudyDetails
